import React, {
  Component
} from 'react';
import {
  Button,
  Modal,
  Form,  
  Icon,
  Input,
  TextArea
} from 'semantic-ui-react';

import { DateInput, TimeInput, DateTimeInput, DatesRangeInput } from 'semantic-ui-calendar-react';

import PubSub from 'pubsub-js';

var request = require("request");

class EditaRequisicoes extends Component {
  constructor(props) {
    super(props);
    this.state = {processando:false}

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {

    let self = this.state;   
    
    this.setState({processando:true});
    
    //let self = this.state;   

    var options = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/apontamento/requisicoes',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);      
      PubSub.publish('edit_requisicoes', self);
      this.setState({processando:false});
    }.bind(this));    

    //PubSub.publish('edit_insumo', self);
  }

 altera(e,{name,value}) {
    this.setState({
      [name]: (name === 'data') ? value.replace(/-/g,'/') : value     
    })  
  }
  
atualizaEstado() {
    let self = this.props.dataItem;
    this.setState(self);
  }
  render() {
    return ( <Modal  style={{width:'96%'}} trigger = { <Icon color='orange' name='edit' onClick={this.atualizaEstado.bind(this)} style={{cursor:'pointer'}} />} centered={false} closeIcon> 
               <Modal.Header >
                 Altera Requisição de Materiais
                < /Modal.Header> 
                <Modal.Content >
        <Form >                  
          <Form.Group widths = 'equal' >
              <Form.Input width={2} type = "number" fluid label = 'Requisição' name='codigo_requisicao' placeholder = 'Requisição' value = {this.state.codigo_requisicao} onChange = {this.altera.bind(this)}/>               
              <Form.Input width={4} type = "text" readOnly fluid label = 'Código' name='codigo' placeholder = 'Código' value = {this.state.codigo} onChange = {this.altera.bind(this)}/> 
              <Form.Input width={8} type = "text" readOnly fluid label = 'Material' name='material'  placeholder = 'Material' value = {this.state.material} onChange = {this.altera.bind(this)}/>                         
              <Form.Input width={2} type = "text" readOnly fluid label = 'UM'  placeholder = 'UM' name='um' value = {this.state.um} onChange = {this.altera.bind(this)}/>           
          < /Form.Group >
          <Form.Group widths = 'equal' >
            <Form.Field width={2}>
              <label>Data</label>           
                <DateInput
                    name="data"
                    value = {this.state.data} 
                    placeholder="Data"                    
                    iconPosition="left"
                    onChange = {this.altera.bind(this)}
                    />
             </Form.Field>            <Form.Field width={8}>
              <label>Área</label>             
              <Input type = "text" list='list_areas' fluid name='area'  placeholder = 'Área' value = {this.state.area} onChange = {this.altera.bind(this)}/>                                       
              <datalist id='list_areas'>                    
                    <option value="BRASSAGEM"/>
                    <option value="ADEGA"/>
                    <option value="FILTRAÇÃO "/>
                    <option value="ETA"/>
                    <option value="ETDI"/>
                    <option value="LABORATÓRIO"/>                    
                    <option value="LINHA DE LATAS"/>
                    <option value="LINHA DE GARRAFAS"/>
                    <option value="CHOPP"/>
                    <option value="UTILIDADES"/>
               </datalist>
            </Form.Field>
            <Form.Input width={4} type = "number" fluid label = 'Quantidade' name='quantidade' placeholder = 'Quantidade' value = {this.state.quantidade} onChange = {this.altera.bind(this)}/>               
          < /Form.Group >
          <Form.Group style={{marginLeft:'0px', marginRight:'0px'}}>
            <TextArea placeholder='Adicione aqui alguma informação relevante para esta movimentação.' name='comentario' value={this.state.comentario} onChange = {this.altera.bind(this)} />
          </Form.Group>
          <Button loading={this.state.processando} disabled={this.state.processando} onClick = {this.handleClick} primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button>           
        < /Form > 
        </Modal.Content> 
    < /Modal >
      );
    }
  }

  export default EditaRequisicoes;
