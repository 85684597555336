import React from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { Icon,  Grid, Container, Segment,  Header } from 'semantic-ui-react';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

// see documentation for supported input formats

var request = require("request");


const PlotlyRenderers = createPlotlyRenderers(Plot);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {data:[]};
    }
  
    componentDidMount(){    

      var options = { method: 'GET',
        url: 'https://inteligenciaindustrial.com/apontamento/ordem/exportar',
        headers: {} 
      };    


      request(options, function (error, response, body) {
        if (error){
          console.log(error)
        }else{
          var a = JSON.parse(body)    
          a = a.map(function(x){
            return  {'ETAPA':x.etapa,
                     'INÍCIO':x.inicio,
                     'FIM':x.fim,
                     'ANO':x.fim.substring(6,10),
                     'MÊS':x.fim.substring(3,5),
                     'DIA':x.fim.substring(0,2),
                     'CÓDIGO PRODUTO':x.codigo_produto,
                     'PRODUTO':x.produto,
                     'BRUTA':x.quantidade_produto,
                     'LÍQUIDA':x.quantidade_liquida,
                     'CÓDIGO INSUMO':x.codigo_insumo,
                     'INSUMO':x.insumo,
                     'UM':x.um,
                     'QUANTIDADE CONSUMIDA':x.quantidade_real}
          })
          this.setState({data:a});          
        }
      }.bind(this));


    }

    render() {
        return (
           <Container style={{padding:'10px'}}>  
        <Segment clearing attached='top'>
          <Header as='h5' floated='left' style={{marginTop:'5px',marginBottom:'0px'}}>
            <Icon name='table'/>
            Análise Rápida de Apontamentos
          </Header>              
         </Segment>            
            <Grid columns={1}>            
              <Grid.Row>
                <Grid.Column>                  
                  <PivotTableUI data={this.state.data} renderers={Object.assign({}, TableRenderers, PlotlyRenderers)} onChange={s => this.setState(s)} {...this.state} />               
                </Grid.Column>          
            </Grid.Row>
          </Grid>
    </Container>
          
          
          
          
          
           
        );
    }
}

export default App;