import React , { Component } from 'react'
import {   Icon,  Header, Image,  Segment,  Container, Menu, Dropdown } from 'semantic-ui-react'

import logo from '../img/getdataind_logo.png'

import PubSub from 'pubsub-js';

import Apontamento from '../apontamento/tabelaApontamento'
import Estrutura from '../estrutura/tabelaEstrutura'
import Materiais from '../materiais/tabelaMaterial'
import Requisicoes from '../requisicoes/tabelaRequisicoes'
import EstoqueIndustrial from '../relatorios/estoqueIndustrial/main'
import AnaliseRapida from '../relatorios/tabelaDinamica/main'
import Pmp from '../pmp/main'

class Principal extends Component {
  constructor(props) {
    super(props);
    this.state = {componente:''}
  }  
  
  componentDidMount(){
          
 }
 
  logout(){
    PubSub.publish('autenticacao', { validado:false });    
  }
  
  mudaComponente(value,{name}){
    
    this.setState({componente:name})    
    
  }
  
  render() {

    
    let componenteRender = ''
    if(this.props.permissao ==='VisualizarRelatorio'){
       if(this.state.componente === 'estoque' || this.state.componente === ''){
          componenteRender = <EstoqueIndustrial permissao={this.props.permissao}/>
      }else{
        alert("Você não tem permissão para acessar este item.")
      }
    }else{       
      if(this.state.componente === 'apontamento'){
        componenteRender = <Apontamento usuario={this.props.usuario} permissao={this.props.permissao}/>
      }else if(this.state.componente === 'estrutura'){
        componenteRender = <Estrutura usuario={this.props.usuario} permissao={this.props.permissao}/>
      }else if(this.state.componente === 'materiais'){
        componenteRender = <Materiais permissao={this.props.permissao}/>
      }else if(this.state.componente === 'requisicoes'){
        componenteRender = <Requisicoes permissao={this.props.permissao}/>
      }else if(this.state.componente === 'estoque'){
        componenteRender = <EstoqueIndustrial permissao={this.props.permissao}/>
      }else if(this.state.componente === 'tabela'){
        componenteRender = <AnaliseRapida permissao={this.props.permissao}/>
      }else if(this.state.componente === 'pmp'){
        componenteRender = <Pmp permissao={this.props.permissao}/>
      }
    }
    return (
            <Container fluid>       
              <Segment clearing basic>
                <Header as='h2'  floated='left'>      
                  <Image src={logo} style={{width:'300px', cursor:'pointer'}} />
                </Header>
                <Header as='h2' floated='right'>
                  <Menu position='right'>
                    <Menu.Menu position='right'>
                      <Dropdown item  icon='settings'>
                        <Dropdown.Menu>                       
                          <Dropdown.Item onClick={this.logout.bind(this)}><Icon name='sign out'/>Sair</Dropdown.Item>
                         </Dropdown.Menu>
                       </Dropdown>
                    </Menu.Menu>
                  </Menu>
                 </Header>                  
              </Segment>  
              <Segment basic clearing>                
                
                <div>  
                <Container style={{padding:'10px'}}>
                
                    <Menu>
                      <Dropdown item icon='bars' simple>
                        <Dropdown.Menu>
                          <Dropdown.Item name='apontamento' onClick={this.mudaComponente.bind(this)}><Icon name='file'/>Ordens</Dropdown.Item>
                          <Dropdown.Item name='estrutura' onClick={this.mudaComponente.bind(this)}><Icon name='beer'/>Estruturas</Dropdown.Item>                                                    
                          <Dropdown.Item name='materiais' onClick={this.mudaComponente.bind(this)}><Icon name='pallet'/>Materiais</Dropdown.Item>                                                    
                          <Dropdown.Item name='requisicoes' onClick={this.mudaComponente.bind(this)}><Icon name='shopping cart'/>Requisições</Dropdown.Item>                                                    
                          <Dropdown.Item name='estoque' onClick={this.mudaComponente.bind(this)}><Icon name='chart bar'/>Relatório Estoque Industrial</Dropdown.Item>                                                    
                                                                  
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu>
                </Container>
                  {componenteRender}
                </div>
              </Segment>
            </Container>
)
  }
}
  
  
  export default Principal
