import React, { Component } from 'react';
import {Button,Modal,Form,Icon} from 'semantic-ui-react';

import PubSub from 'pubsub-js';
const random = require('../utilitarios/chave');

var request = require("request");

class AdicionaMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: random(50),      
      codigo:'',
      material:'',
      um:'',
      tipo:'',
      key_usuario:this.props.dataItem,
      processando:false
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    
   let self = this.state;   
   this.setState({processando:true});

    this.setState({
      key: random(50)
    });
   // let self = this.state;   

    var options = {
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/apontamento/material',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);
      console.log(body);
      PubSub.publish('add_material', body);
      this.setState({processando:false});
    }.bind(this));    

    this.setState({
      key: random(50),
      codigo:'',
      material:'',
      um:'',
      key_usuario:this.props.dataItem
    })
    
   // PubSub.publish('add_insumo', self);
    
  }

 altera(e,{name,value}) {
    this.setState({
      [name]: e.target.value      
    })  
  }
  
  render() {
    return ( <Modal size = {'fullscreen'} trigger = { < Button primary floated = 'right' size = 'mini' icon > < Icon name = 'plus' / > < /Button>} centered={false} closeIcon> 
               <Modal.Header > Materiais < /Modal.Header> 
                <Modal.Content >
        <Form >        
         <Form.Group widths = 'equal' >
              <Form.Input width={4} type = "text" fluid label = 'Código' name='codigo' placeholder = 'Código' value = {this.state.codigo} onChange = {this.altera.bind(this)}/> 
              <Form.Input width={8} type = "text" fluid label = 'Material' name='material'  placeholder = 'Material' value = {this.state.material} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={2} type = "text" fluid label = 'Tipo' name='tipo'  placeholder = 'Tipo' value = {this.state.tipo} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={2} type = "text" fluid label = 'UM'  placeholder = 'UM' name='um' value = {this.state.um} onChange = {this.altera.bind(this)}/>           
          < /Form.Group >
          <Button onClick = {this.handleClick} loading={this.state.processando} disabled={this.state.processando} primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button> 
        < /Form > 
        </Modal.Content> 
    < /Modal >
      );
    }
  }

  export default AdicionaMaterial;
