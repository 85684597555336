import React, { Component } from 'react';
import { Table, Header, Container, Segment, Icon, Modal, Button, Dimmer, Loader,Popup } from 'semantic-ui-react';
import EditaRequisicao from './editaRequisicoes';
import AdicionaRequisicao from './adicionaRequisicoes';

import PubSub from 'pubsub-js';

import exportFromJSON from 'export-from-json'

var request = require("request");

const formatDecimal = require('format-decimal');
var opts =  {
            decimal   : ',',
            precision : 4,
            thousands : '.'
        }


class TabelaRequisicoes extends Component {

  constructor(props){
    super(props);
      this.state = {
        insumos:[],
        request:true
      }
  } 

  componentDidMount(){     
    this.setState({request:true})

    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/requisicoes',
      headers: {} 
    };

    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)
            a = a.filter(function(item){return item.key_cenario === this.props.dataItem}.bind(this))
        this.setState({insumos:a, request:false});      
      }
    }.bind(this));

    
    PubSub.subscribe('add_requisicoes', function (topic, data) {      
      let estado = this.state.insumos;
          estado.unshift(data)
      this.setState({insumos:estado})
    }.bind(this));
   
    PubSub.subscribe('edit_requisicoes', function (topic, data) {      
      let estado = this.state.insumos;      
      for (var i in estado) {
        if (estado[i].key === data.key) {
            estado[i] = data;
            break;
         }
      }

      this.setState({insumos:estado})
    }.bind(this));
  }
  
  remove(key){
    let estado = this.state.insumos;

    var options = { method: 'DELETE',
          url: 'https://inteligenciaindustrial.com/apontamento/requisicoes',
          headers:{ 'content-type': 'application/json' },
          body: { key: key },
          json: true };

     request(options, function (error, response, body) {
          if (error) throw new Error(error);
          console.log(body);
      });


    for(var i = estado.length - 1; i >= 0; i--) {
        if(estado[i].key === key) {
           estado.splice(i, 1);
        }
    }    
    this.setState({insumos:estado});
  }
  
    
  exportarRequisicoes(){
    
    let data ;
    let fileName = 'requisicoes'
    let exportType = 'xls'
    
    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/requisicoes',
      headers: {} 
    };    
    
    
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body);
        
        for(var i in a){
          a[i].quantidade = a[i].quantidade.replace('.',',');  
        }
        
        data = a;
        exportFromJSON({ data, fileName, exportType })
      }
    }.bind(this));
       
    
  }
  
  render() {
    let styleRemove = {cursor:'pointer'};   
    
    if(this.props.permissao === 'Visualizar'){
      styleRemove = { display:'none'};
    }
    
    return (        
             <Container style={{padding:'10px'}}>
            <Segment clearing attached='top'>
              <Header as='h5' floated='left' style={{marginTop:'5px',marginBottom:'0px'}}>
                <Icon name='shopping cart'/>
                Requisições de Materiais
              </Header>     
            <Button.Group floated='right'>  
              < Button onClick={this.exportarRequisicoes.bind(this)} floated = 'right' size = 'mini' icon > < Icon name = 'file excel' / > < /Button>
              
             <div style={styleRemove}> 
              <AdicionaRequisicao dataItem={this.props.dataItem}/>
             </div>
            </Button.Group>
            </Segment>
              <Dimmer active={this.state.request} inverted>
                <Loader size='large'></Loader>
              </Dimmer>              
              <Table size='small' textAlign='center' celled structured attached>
                  <Table.Header>
                    <Table.Row>                      
                      <Table.HeaderCell>Requisição</Table.HeaderCell>       
                      <Table.HeaderCell>Data</Table.HeaderCell>       
                      <Table.HeaderCell>Área</Table.HeaderCell>                      
                      <Table.HeaderCell>Código</Table.HeaderCell>                      
                      <Table.HeaderCell>Material</Table.HeaderCell>                                            
                      <Table.HeaderCell>UM</Table.HeaderCell>                                            
                      <Table.HeaderCell>Quantidade</Table.HeaderCell>                      
                      <Table.HeaderCell style={styleRemove} width={1}><Icon name='remove circle' /></Table.HeaderCell>
                      <Table.HeaderCell style={styleRemove} width={1}><Icon name='edit' /></Table.HeaderCell>
                      <Table.HeaderCell style={styleRemove} width={1}><Icon name='comment' /></Table.HeaderCell>     
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.insumos.map(item =>
                    <Table.Row key={item.key}>                      
                      <Table.Cell>{item.codigo_requisicao}</Table.Cell>
                      <Table.Cell>{item.data}</Table.Cell>
                      <Table.Cell>{item.area}</Table.Cell>
                      <Table.Cell>{item.codigo}</Table.Cell>                                            
                      <Table.Cell>{item.material}</Table.Cell>                                            
                      <Table.Cell>{item.um}</Table.Cell>                         
                      <Table.Cell>{formatDecimal(item.quantidade*1, opts)}</Table.Cell>                         
                      <Table.Cell style={styleRemove}>                        
                          <Modal trigger={<Icon color='red' name='remove circle' style={{cursor:'pointer'}} data-key={item.key}/>} basic size='small'>
                              <Header icon='trash alternate' content='Confirmação de deleção de item' />
                              <Modal.Content>
                                <p>
                                  Deseja realmente deletar este item?
                                </p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button primary onClick={this.remove.bind(this,item.key)}>
                                  <Icon name='checkmark' /> Sim
                                </Button>
                              </Modal.Actions>
                            </Modal>  
                      </Table.Cell>
                      <Table.Cell style={styleRemove}><EditaRequisicao dataItem={item} /></Table.Cell>
                      <Table.Cell style={styleRemove}><Popup trigger={<Icon name='comment' color='green' style={{visibility: (item.comentario === "" || item.comentario === null ) ? "hidden"  : ""}} />} content={item.comentario} /></Table.Cell>
                    </Table.Row>
                  )}
                  </Table.Body>                  
                </Table>
            </Container>                  
    );
  }
}
                                  
export default TabelaRequisicoes;
