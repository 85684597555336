import React, { Component } from 'react';
import { Table, Header, Container, Segment, Icon, Modal, Button, Dimmer, Loader } from 'semantic-ui-react';
import EditaMaterial from './editaMaterial';
import AdicionaMaterial from './adicionaMaterial';

import PubSub from 'pubsub-js';

var request = require("request");

class TabelaMaterial extends Component {

  constructor(props){
    super(props);
      this.state = {
        insumos:[],
        request:true
      }
  } 

  componentDidMount(){     

    this.setState({request:true})
    
    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/material',
      headers: {} 
    };

    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)
            a = a.filter(function(item){return item.key_cenario === this.props.dataItem}.bind(this))
        this.setState({insumos:a, request:false});      
      }
    }.bind(this));

    
    PubSub.subscribe('add_material', function (topic, data) {      
      let estado = this.state.insumos;
          estado.push(data)
      this.setState({insumos:estado})
    }.bind(this));
   
    PubSub.subscribe('edit_material', function (topic, data) {      
      let estado = this.state.insumos;      
      for (var i in estado) {
        if (estado[i].key === data.key) {
            estado[i] = data;
            break;
         }
      }

      this.setState({insumos:estado})
    }.bind(this));
  }
  
  remove(key){
    let estado = this.state.insumos;

    var options = { method: 'DELETE',
          url: 'https://inteligenciaindustrial.com/apontamento/material',
          headers:{ 'content-type': 'application/json' },
          body: { key: key },
          json: true };

     request(options, function (error, response, body) {
          if (error) throw new Error(error);
          console.log(body);
      });


    for(var i = estado.length - 1; i >= 0; i--) {
        if(estado[i].key === key) {
           estado.splice(i, 1);
        }
    }    
    this.setState({insumos:estado});
  }
  
  render() {
    
    let styleRemove = {cursor:'pointer'};   
    
    if(this.props.permissao === 'Visualizar'){
      styleRemove = { display:'none'};
    }
    
    return (        
             <Container style={{padding:'10px'}}>
            <Segment clearing attached='top'>
              <Header as='h5' floated='left' style={{marginTop:'5px',marginBottom:'0px'}}>
                <Icon name=' pallet'/>
                Cadastro de Materiais
              </Header>   
              <div style={styleRemove}>      
                <AdicionaMaterial dataItem={this.props.dataItem}/>
              </div>
            </Segment>
              <Dimmer active={this.state.request} inverted>
                <Loader size='large'></Loader>
              </Dimmer>              
              <Table size='small' textAlign='center' celled structured attached>
                  <Table.Header>
                    <Table.Row>                      
                      <Table.HeaderCell>Código</Table.HeaderCell>                      
                      <Table.HeaderCell>Material</Table.HeaderCell>                      
                      <Table.HeaderCell>Tipo</Table.HeaderCell>  
                      <Table.HeaderCell>UM</Table.HeaderCell>                      
                      <Table.HeaderCell style={styleRemove} width={1}><Icon name='remove circle' /></Table.HeaderCell>
                      <Table.HeaderCell style={styleRemove} width={1}><Icon name='edit' /></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.insumos.map(item =>
                    <Table.Row key={item.key}>                      
                      <Table.Cell>{item.codigo}</Table.Cell>
                      <Table.Cell>{item.material}</Table.Cell>                                            
                      <Table.Cell>{item.tipo}</Table.Cell>                                            
                      <Table.Cell>{item.um}</Table.Cell>                         
                      <Table.Cell style={styleRemove}>                        
                          <Modal trigger={<Icon color='red' name='remove circle' style={{cursor:'pointer'}} data-key={item.key}/>} basic size='small'>
                              <Header icon='trash alternate' content='Confirmação de deleção de item' />
                              <Modal.Content>
                                <p>
                                  Deseja realmente deletar este item?
                                </p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button primary onClick={this.remove.bind(this,item.key)}>
                                  <Icon name='checkmark' /> Sim
                                </Button>
                              </Modal.Actions>
                            </Modal>  
                      </Table.Cell>
                      <Table.Cell style={styleRemove}><EditaMaterial dataItem={item} /></Table.Cell>
                    </Table.Row>
                  )}
                  </Table.Body>                  
                </Table>
            </Container>                  
    );
  }
}
                                  
export default TabelaMaterial;
