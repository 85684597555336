import React, { Component } from 'react';
import { Button, Modal, Form,  Icon } from 'semantic-ui-react';


import exportFromJSON from 'export-from-json'

var request = require("request");

class ExportaOrdens extends Component {
  constructor(props) {
    super(props);
    this.state = {
        filtroIncialOrdens:0,
        filtroFinalOrdens:0
    }    
  }

  componentDidMount(){     

  }
  
  handleClick() {

  }

  
atualizaEstado() {

}
  
atualizaValor(e,{name,value}){

  }
  
 alteraFiltroOrdens(e,{name,value}) {
    this.setState({
      [name]:value   
    })
  }
  
    exportarOrdens(){
    
    let data ;
    let fileName = 'ordens'
    let exportType = 'xls'
    
    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/ordem/exportar/'+this.state.filtroIncialOrdens+'&'+this.state.filtroFinalOrdens,
      headers: {} 
    };    
    
    
    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)            
        data = a;
        exportFromJSON({ data, fileName, exportType })
      }
    });      
    
  }
  
  
  render() {
    return ( <Modal size='mini' trigger = { < Button floated = 'right' size = 'mini' icon > < Icon name = 'file excel' / > < /Button>} centered={true} closeIcon> 
                    <Modal.Header > Filtro de Ordens < /Modal.Header> 
                    <Modal.Content scrolling >
                                    
                       <Form>
                        <Form.Field>
                          <label>Nº de ordem inicial</label>
                          <Form.Input type='number' placeholder='Nº de ordem inicial' name='filtroIncialOrdens' value={this.state.filtroIncialOrdens} onChange={this.alteraFiltroOrdens.bind(this)} />
                        </Form.Field>
                        <Form.Field>
                          <label>Nº de ordem final</label>
                          <Form.Input  type='number' placeholder='Nº de ordem final' name='filtroFinalOrdens' value={this.state.filtroFinalOrdens}  onChange={this.alteraFiltroOrdens.bind(this)} />
                        </Form.Field>
                      </Form>
                                    
                    </Modal.Content> 
                    <Modal.Actions>
                      <Button onClick = {this.exportarOrdens.bind(this)} loading={this.state.processando} disabled={this.state.processando}  primary icon labelPosition = 'left' > < Icon name = 'file excel' / > Exportar < /Button>                               
                    </Modal.Actions>
                  </Modal>
      );
    }
  }

  export default ExportaOrdens;
