import React, { Component } from 'react';
import {  Modal,   Icon, Table} from 'semantic-ui-react';


const formatDecimal = require('format-decimal');
var opts =  {
            decimal   : ',',
            precision : 4,
            thousands : '.'
        }

class Detalhe extends Component {
  constructor(props) {
    super(props);
    this.state = {processando:false, movimentos:[]}    
  }

  componentDidMount(){     
  }
  

atualizaEstado() {
  
  let movimentos = this.props.dataProvider;
  movimentos = movimentos.filter(function(i) {
    if (i.codigo === this.props.dataCodigo) {
      return i;
    }
  }.bind(this))
  
  this.setState({movimentos:movimentos})
}

  
  render() {
    return ( <Modal style={{width:'95%'}} trigger = { <Icon  name='window restore outline' onClick={this.atualizaEstado.bind(this)} style={{cursor:'pointer'}} />} centered={true} closeIcon> 
               <Modal.Header > Detalhe de Movimentos < /Modal.Header> 
                <Modal.Content scrolling >
                <Table size='small' textAlign='center' celled structured attached selectable>
                  <Table.Header>
                    <Table.Row>                                            
                      <Table.HeaderCell>Data</Table.HeaderCell>                      
                      <Table.HeaderCell>Código</Table.HeaderCell>                      
                      <Table.HeaderCell>Descrição</Table.HeaderCell>
                      <Table.HeaderCell>Consumos</Table.HeaderCell>      
                      <Table.HeaderCell>Requisições</Table.HeaderCell>                      
                                                           
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.movimentos.map(item =>
                    <Table.Row key={item.DATA}>                      
                      <Table.Cell>{item.DATA}</Table.Cell>
                      <Table.Cell>{item.codigo}</Table.Cell>
                      <Table.Cell>{item.insumo}</Table.Cell>                                                                                        
                      <Table.Cell>{formatDecimal(item.consumos*1, opts)}</Table.Cell>                         
                      <Table.Cell>{formatDecimal(item.requisicoes*1, opts)}</Table.Cell>                                               
                    </Table.Row>
                  )}
                  </Table.Body>                  
                </Table>   
       
                </Modal.Content>         
            </Modal >
      );
    }
  }

  export default Detalhe;
