import React, { Component } from 'react';
import { Button, Modal, Form, Input, Icon, Table, Header, Dimmer, Loader } from 'semantic-ui-react';
import { DateInput, TimeInput, DateTimeInput, DatesRangeInput } from 'semantic-ui-calendar-react';


import PubSub from 'pubsub-js';

var request = require("request");

class EditaInsumo extends Component {
  constructor(props) {
    super(props);
    this.state = {processando:false, estruturas:[], estruturaFiltrada:[],request:true}
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(){     
    
   /* var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/estrutura',
      headers: {} 
    };

    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)    
        
        for (var o in a){
          delete a[o].id
        }        
        this.setState({estruturas:a});        
        
      }
    }.bind(this));*/
    
    

    
     
    
    
   /*  PubSub.subscribe('consumo_consulta', function (topic, data) {  
       
       let a = data;
        
        for (var o in a){
          delete a[o].id
        
          a[o].codigo = a[o].codigo_insumo;
          a[o].quantidade = a[o].quantidade_padrao;
          a[o].quantidade_por_producao = Math.round(this.props.dataItem.quantidade_produto * a[o].quantidade_padrao*1000000)/1000000;
          
        }      
        
        a = a.filter(function(item){
          return item.key_ordem === this.props.dataItem.key
        }.bind(this))
        
        this.setState({estruturaFiltrada:a},function(){  }.bind(this));
        console.log('here')

    }.bind(this));
    */
    
    
  }
  
  handleClick() {

    let self = this.state;   
    
    this.setState({processando:true});
    
    //let self = this.state;   

    var options = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/apontamento/ordem',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);      
      PubSub.publish('edit_ordem', self);
      this.setState({processando:false});
    }.bind(this));    

    
    console.log('Estrutura Filtrada')
    console.log(this.state.estruturaFiltrada)
    for(var i in this.state.estruturaFiltrada){
      console.log('-----------------------------');
      console.log(this.state.estruturaFiltrada[i]);
    
    var options2 = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/apontamento/consumo',
      headers: {              
        'content-type': 'application/json'
      },
      body: this.state.estruturaFiltrada[i],
      json: true
    };
    
    request(options2, function(error, response, body) {
      if (error) throw new Error(error);
      console.log(body);     
    }); 
      
    } 
  }

  altera(e,{name,value}) {
    this.setState({
      [name]: (name === 'inicio' || name === 'fim') ? value.replace(/-/g,'/') : value     
    },function(){
      let estruturaFiltrada = [];
      if(name === 'codigo_produto'){
        estruturaFiltrada = this.state.estruturas.filter(function(item){
          return item.codigo_produto === this.state.codigo_produto;
        }.bind(this))
        
        for(var i in estruturaFiltrada){
          estruturaFiltrada[i].quantidade_real = '';
          
          if(this.state.etapa === 'ENVASAMENTO'){
            estruturaFiltrada[i].quantidade_por_producao = Math.round(this.state.quantidade_liquida * estruturaFiltrada[i].quantidade*1000000)/1000000;   
          }else{
            estruturaFiltrada[i].quantidade_por_producao = Math.round(this.state.quantidade_produto * estruturaFiltrada[i].quantidade*1000000)/1000000;
          }
        }        
        
        if(estruturaFiltrada.length>=1){
          this.setState({produto:estruturaFiltrada[0].produto})
        }
        
        this.setState({estruturaFiltrada:estruturaFiltrada})
      }
      
     if(name === 'quantidade_liquida' && this.state.etapa === 'ENVASAMENTO'){
        estruturaFiltrada = this.state.estruturaFiltrada
        
        for(var z in estruturaFiltrada){
          //estruturaFiltrada[i].quantidade_real = 0;
          estruturaFiltrada[z].quantidade_por_producao = Math.round(this.state.quantidade_liquida * estruturaFiltrada[z].quantidade*1000000)/1000000;
        }        
        
       /* if(estruturaFiltrada.length>=1){
          this.setState({produto:estruturaFiltrada[0].produto})
        }
        */
        
        this.setState({estruturaFiltrada:estruturaFiltrada})
      }else if(name === 'quantidade_produto' && this.state.etapa !== 'ENVASAMENTO'){
        estruturaFiltrada = this.state.estruturaFiltrada
        
        for(var o in estruturaFiltrada){
          //estruturaFiltrada[i].quantidade_real = 0;
          estruturaFiltrada[o].quantidade_por_producao = Math.round(this.state.quantidade_produto * estruturaFiltrada[o].quantidade*1000000)/1000000;
        }        
        
       /* if(estruturaFiltrada.length>=1){
          this.setState({produto:estruturaFiltrada[0].produto})
        }
        */
        
        this.setState({estruturaFiltrada:estruturaFiltrada})
               
               }  
    }.bind(this))   
    
    console.log(this.state)
  }
  
atualizaEstado() {
    let self = this.props.dataItem;
        self.key_usuario = this.props.usuario;
    this.setState(self);
  
    var options2 = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/consumo',
      headers: {} 
    };

  this.setState({request:true})
    request(options2, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)    
        
        for (var o in a){
          delete a[o].id
          
          
          
          a[o].codigo = a[o].codigo_insumo;
          a[o].quantidade = a[o].quantidade_padrao;
          
          if(this.props.dataItem.etapa === 'ENVASAMENTO'){
             a[o].quantidade_por_producao = Math.round(this.props.dataItem.quantidade_liquida * a[o].quantidade_padrao*1000000)/1000000;
        }else{
             a[o].quantidade_por_producao = Math.round(this.props.dataItem.quantidade_produto * a[o].quantidade_padrao*1000000)/1000000;

        }
          
        }      
        
        a = a.filter(function(item){
          return item.key_ordem === this.props.dataItem.key
        }.bind(this))
        
        this.setState({estruturaFiltrada:a},function(){ console.log(this.state) }.bind(this));      
        this.setState({request:false})
        
      }
    }.bind(this));
}
  
atualizaValor(e,{name,value}){
   
   let estado = this.state.estruturaFiltrada;      
      for (var i in estado) {
        if (estado[i].key === name) {
            estado[i].quantidade_real = this.state.valor_real_apontamento;
            estado[i].key_usuario = this.props.usuario;
            break;
         }
      }
      this.setState({estruturaFiltrada:estado})
      this.setState({valor_real_apontamento:''})
  }
  
  render() {
    return ( <Modal style={{width:'95%'}} trigger = { <Icon color='orange' name='edit' onClick={this.atualizaEstado.bind(this)} style={{cursor:'pointer'}} />} centered={true} closeIcon> 
               <Modal.Header > Alteração de Apontamento < /Modal.Header> 
                <Modal.Content scrolling >
        <Form >                  
          <Form.Group widths = 'equal' >
              <Form.Field width={4}>
                <label>Etapas</label>
                <Input list='etapas'type = "text" readOnly name='etapa' fluid placeholder='Etapa' value = {this.state.etapa} onChange = {this.altera.bind(this)}/>               
                <datalist id='etapas'>                
                  <option value='BRASSAGEM' />
                  <option value='FERMENTAÇÃO' />
                  <option value='MATURAÇÃO' />
                  <option value='FILTRAÇÃO' />
                  <option value='ENVASAMENTO'/>
                </datalist>
              </Form.Field>
              <Form.Field width={4}>
                 <label>Código Produto</label>
                 <Input list='produtos' type = "text" readOnly name='codigo_produto' fluid placeholder = 'Código Produto' value = {this.state.codigo_produto} onChange = {this.altera.bind(this)}/> 
                 <datalist id='produtos'>
                  <option value='31001099A'/>    
                  <option value='31001099B'/>   
                  <option value='31001099C'/>    

                </datalist>
              </Form.Field>
              <Form.Input width={5} type = "text" style={{width:'300px'}} readOnly name='produto' fluid label = 'Produto'  placeholder = 'Produto' value = {this.state.produto} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={4} type = "number" name='quantidade_produto' fluid label = 'Bruta'  placeholder = 'Bruta' value = {this.state.quantidade_produto} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={4} type = "number" name='quantidade_liquida' fluid label = 'Líquida'  placeholder = 'Líquida' value = {this.state.quantidade_liquida} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={4} type = "number" name='mosto_basico' fluid label = 'Mosto Básico'  placeholder = 'Mosto Básico' value = {this.state.mosto_basico} onChange = {this.altera.bind(this)}/>           
          < /Form.Group >
          <Form.Group widths = 'equal' >                          
              <Form.Field width={2}>
              <label>Início</label>           
                <DateTimeInput
                    name="inicio"
                    value = {this.state.inicio} 
                    placeholder="Início"                    
                    iconPosition="left"
                    onChange = {this.altera.bind(this)}
                    />
             </Form.Field>
             <Form.Field width={2}>
              <label>Fim</label>           
                <DateTimeInput
                    name="fim"
                    value = {this.state.fim} 
                    placeholder="Fim"                    
                    iconPosition="left"
                    onChange = {this.altera.bind(this)}
                    />
             </Form.Field>
              <Form.Input width={2} type = "text" readOnly name='ordem' fluid label = 'Ordem'  placeholder = 'Ordem' value = {this.state.ordem} onChange = {this.altera.bind(this)}/>                              
              <Form.Input width={2} type = "text" name='controle' fluid label = 'Controle'  placeholder = 'Controle' value = {this.state.controle} onChange = {this.altera.bind(this)}/>                              
          < /Form.Group >
          <Form.Group widths = 'equal' >                          
              <Form.Input width={2} type = "text" name='recurso' fluid label = 'Recurso'  placeholder = 'Recurso' value = {this.state.recurso} onChange = {this.altera.bind(this)}/>                                            
          < /Form.Group >
        < /Form > 
        <Table size='small' textAlign='center' celled structured attached>
              <Dimmer active={this.state.request} inverted>
                <Loader size='large'></Loader>
              </Dimmer>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Código</Table.HeaderCell>
                      <Table.HeaderCell>Insumo</Table.HeaderCell>                      
                      <Table.HeaderCell>UM</Table.HeaderCell>      
                      <Table.HeaderCell>Quantidade Padrão para Unidade Básica</Table.HeaderCell>                                            
                      <Table.HeaderCell>Quantidade Padrão</Table.HeaderCell>                                            
                      <Table.HeaderCell>Quantidade Real</Table.HeaderCell>                                                                  
                      <Table.HeaderCell width={1}><Icon name='edit' /></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.estruturaFiltrada.map(item =>
                    <Table.Row key={item.key}>
                      <Table.Cell>{item.codigo}</Table.Cell>
                      <Table.Cell>{item.insumo}</Table.Cell>
                      <Table.Cell>{item.um}</Table.Cell>                      
                      <Table.Cell>{item.quantidade}</Table.Cell>                                            
                      <Table.Cell>{item.quantidade_por_producao}</Table.Cell>                                            
                      <Table.Cell>{item.quantidade_real}</Table.Cell>                                            
                      <Table.Cell>
                         <Modal size='small' trigger={<Icon color='orange' name='edit' style={{cursor:'pointer'}} />} closeIcon>
                            <Modal.Header>Ajustar Quantidade Real</Modal.Header>
                            <Modal.Content>
                              <Header as='h5'>{item.codigo} - {item.insumo}:  {item.quantidade_por_producao} {item.um}</Header>
                              <Form>                                
                                  <Form.Input autoFocus data-key={item.key} name='valor_real_apontamento' value={this.state.valor_real_apontamento} type='number' fluid label = 'Valor Real' placeholder = 'Valor Real' onChange = {this.altera.bind(this)}/>                                
                              </Form >
                            </Modal.Content>
                            <Modal.Actions>
                              <Button icon='check' name={item.key} primary content='Confirmar' labelPosition = 'left'  onClick={this.atualizaValor.bind(this)} />
                            </Modal.Actions>
                          </Modal>                        
                      </Table.Cell>
                    </Table.Row>
                  )}
                  </Table.Body>                  
             </Table>  
        </Modal.Content> 
        <Modal.Actions>
          <Button onClick = {this.handleClick} loading={this.state.processando} disabled={this.state.processando}  primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button>                               
        </Modal.Actions>
    < /Modal >
      );
    }
  }

  export default EditaInsumo;
