import React, { Component } from 'react';
import {Button,Modal,Form,Icon, Input} from 'semantic-ui-react';

import PubSub from 'pubsub-js';
const random = require('../utilitarios/chave');

var request = require("request");

class AdicionaInsumo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: random(50),
      data:'',
      ordem:'',
      controle:'',
      etapa:'',
      codigo:'',
      insumo:'',
      quantidade:0,
      um:'',
      codigo_produto:'',
      produto:'',
      quantidade_produto:'',
      key_usuario:this.props.dataItem,
      processando:false
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    
   let self = this.state;   

    this.setState({
      key: random(50)
    });
   // let self = this.state;   
    this.setState({processando:true});

    var options = {
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/apontamento/estrutura',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);
      console.log(body);
      PubSub.publish('add_estrutura', body);
      this.setState({processando:false});
    }.bind(this));    

    
    
    this.setState({
      key: random(50),
      data:'',
      ordem:'',
      controle:'',
      etapa:'',
      codigo:'',
      insumo:'',
      quantidade:0,
      codigo_produto:'',
      produto:'',
      quantidade_produto:'',
      um:'',
      key_usuario:this.props.dataItem
    })
    
   // PubSub.publish('add_insumo', self);
    
  }

  alteraData(e) {
    this.setState({
      data: e.target.value      
    })    
    
  }
  
  alteraEtapa(e) {
    this.setState({
      etapa: e.target.value      
    })
    
  }
  
  alteraCodigo(e) {
    this.setState({
      codigo: e.target.value      
    })    
    
  }

  alteraInsumo(e) {
    this.setState({
      insumo: e.target.value      
    })    
    
  }
  
  alteraQuantidade(e) {
    this.setState({
      quantidade: e.target.value      
    })    
    
  }
  
  alteraUM(e) {
    this.setState({
      um: e.target.value      
    })    
    
  }

  
alteraCodigoProduto(e){
      this.setState({
      codigo_produto: e.target.value      
    })  
  
}
alteraProduto(e){
      this.setState({
      produto: e.target.value      
    })  
  
}
 
  
alteraOrdem(e){
      this.setState({
      ordem: e.target.value      
    })  
  
}
alteraControle(e){
      this.setState({
      controle: e.target.value      
    })  
  
}
  
alteraQuantidadeProduto(e){
      this.setState({
      quantidade_produto: e.target.value      
    })    
}
  
  render() {
    return ( <Modal size = {'fullscreen'} trigger = { < Button primary floated = 'right' size = 'mini' icon > < Icon name = 'plus' / > < /Button>} centered={false} closeIcon> 
               <Modal.Header > Estrutura < /Modal.Header> 
                <Modal.Content >
        <Form >        
         <Form.Group widths = 'equal' >
              <Form.Input width={4} type = "text" fluid label = 'Código Produto' placeholder = 'Código Produto' value = {this.state.codigo_produto} onChange = {this.alteraCodigoProduto.bind(this)}/> 
              <Form.Input width={10} type = "text" fluid label = 'Produto'  placeholder = 'Produto' value = {this.state.produto} onChange = {this.alteraProduto.bind(this)}/>           
              <Form.Input width={2} type = "text" fluid label = 'Quantidade'  placeholder = 'Quantidade' value = {this.state.quantidade_produto} onChange = {this.alteraQuantidadeProduto.bind(this)}/>           
          < /Form.Group >
          <Form.Group widths = 'equal' >              
            <Form.Field width={2}>
              <label>Etapas</label>
              <Input list='etapas'type = "text" fluid placeholder = 'Etapa' value = {this.state.etapa} onChange = {this.alteraEtapa.bind(this)}/>               
              <datalist id='etapas'>                
                <option value='BRASSAGEM' />
                <option value='FERMENTAÇÃO' />
                <option value='MATURAÇÃO' />
                <option value='FILTRAÇÃO' />
                <option value='ENVASAMENTO'/>
              </datalist>
            </Form.Field>
              <Form.Input width={2} type = "text" fluid label = 'Código'  placeholder = 'Código' value = {this.state.codigo} onChange = {this.alteraCodigo.bind(this)}/>                
              <Form.Input width={5} type = "text" fluid label = 'Insumo'  placeholder = 'Insumo' value = {this.state.insumo} onChange = {this.alteraInsumo.bind(this)}/> 
              <Form.Input width={2} type = "number" fluid label = 'Quantidade'  placeholder = 'Quantidade' value = {this.state.quantidade} onChange = {this.alteraQuantidade.bind(this)}/> 
              <Form.Input width={1} type = "text" fluid label = 'UM'  placeholder = 'UM' value = {this.state.um} onChange = {this.alteraUM.bind(this)}/> 
          < /Form.Group >
          <Button loading={this.state.processando} disabled={this.state.processando} onClick = {this.handleClick} primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button> 
        < /Form > 
        </Modal.Content> 
    < /Modal >
      );
    }
  }

  export default AdicionaInsumo;
