import React, { Component } from 'react';
import { Table, Header, Container, Segment, Icon, Modal, Button, Input, Menu, Dimmer, Loader, Form } from 'semantic-ui-react';
import EditaApontamento from './editaApontamento';
import AdicionaApontamento from './adicionaApontamento';
import ExportaOrdens from './filtroOrdensExportacao';

import PubSub from 'pubsub-js';



var request = require("request");

const formatDecimal = require('format-decimal');
var opts =  {
            decimal   : ',',
            precision : 2,
            thousands : '.'
        }

class TabelaInsumos extends Component {

  constructor(props){
    super(props);
      this.state = {
        insumos:[],
        insumosCompleto:[],
        consultaConsumos:[],
        request:true
      }
  } 

  componentDidMount(){
        
  /*  var options2 = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/consumo',
      headers: {} 
    };
    
   request(options2, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)    
    
        PubSub.publish('consumo_consulta', a);
        this.setState({
          consultaConsumos: a
        }, function() {
          console.log(this.state)
        }.bind(this)); 
        
      }
    }.bind(this));    
    */
    
    this.setState({request:true})

    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/ordem',
      headers: {} 
    };

    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)
        
            for(var o in a){
              if(a[o].etapa ==="BRASSAGEM"){
                a[o].cor = ''
                 }else if(a[o].etapa ==="FERMENTAÇÃO"){
                 a[o].cor = '#2b5797'
              }else if(a[o].etapa ==="MATURAÇÃO"){
                 a[o].cor = '#ffc40d'
              }else if(a[o].etapa ==="FILTRAÇÃO"){
                 a[o].cor = '#b91d47'
              }else if(a[o].etapa ==="ENVASAMENTO"){
                 a[o].cor = '#1e7145'
              }  
                    
            
            }
            a = a.filter(function(item){return item.key_cenario === this.props.dataItem}.bind(this))
        this.setState({insumos:a,insumosCompleto:a,request:false});      
      }
    }.bind(this));

    
    PubSub.subscribe('add_ordem', function (topic, data) {      
      let estado = this.state.insumos;
          estado.unshift(data)
       for(var o in estado){
              if(estado[o].etapa ==="BRASSAGEM"){
                estado[o].cor = ''
                 }else if(estado[o].etapa ==="FERMENTAÇÃO"){
                 estado[o].cor = '#2b5797'
              }else if(estado[o].etapa ==="MATURAÇÃO"){
                 estado[o].cor = '#ffc40d'
              }else if(estado[o].etapa ==="FILTRAÇÃO"){
                 estado[o].cor = '#b91d47'
              }else if(estado[o].etapa ==="ENVASAMENTO"){
                 estado[o].cor = '#1e7145'
              }}
      this.setState({insumos:estado})
    }.bind(this));
   
    PubSub.subscribe('edit_ordem', function (topic, data) {      
      let estado = this.state.insumos;      
      for (var i in estado) {
        if (estado[i].key === data.key) {
            estado[i] = data;
            break;
         }
      }      
      this.setState({insumos:estado})
      
      let estado2 = this.state.insumosCompleto;      
      for (var p in estado2) {
        if (estado2[p].key === data.key) {
            estado2[p] = data;
            break;
         }
      }
      this.setState({insumosCompleto:estado2})
      
    }.bind(this));
  }
  
  

  
  
  atualizaApontamentos(){
    this.setState({request:true})
    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/ordem',
      headers: {} 
    };

    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)
        
            for(var o in a){
              if(a[o].etapa ==="BRASSAGEM"){
                a[o].cor = ''
                 }else if(a[o].etapa ==="FERMENTAÇÃO"){
                 a[o].cor = '#2b5797'
              }else if(a[o].etapa ==="MATURAÇÃO"){
                 a[o].cor = '#ffc40d'
              }else if(a[o].etapa ==="FILTRAÇÃO"){
                 a[o].cor = '#b91d47'
              }else if(a[o].etapa ==="ENVASAMENTO"){
                 a[o].cor = '#1e7145'
              }  
                    
            
            }
            a = a.filter(function(item){return item.key_cenario === this.props.dataItem}.bind(this))
        this.setState({insumos:a,insumosCompleto:a,request:false});         
      }
    }.bind(this));
    
  }
  
  remove(key){
    let estado = this.state.insumos;

    var options = { method: 'DELETE',
          url: 'https://inteligenciaindustrial.com/apontamento/ordem',
          headers:{ 'content-type': 'application/json' },
          body: { key: key },
          json: true };

     request(options, function (error, response, body) {
          if (error) throw new Error(error);
    
      });


    for(var i = estado.length - 1; i >= 0; i--) {
        if(estado[i].key === key) {
           estado.splice(i, 1);
        }
    }    
    this.setState({insumos:estado});
    this.setState({insumosCompleto:estado});
  }
  
  filtra(e,{name,value}){
    if(value !== ''){
        let insumos = [...this.state.insumosCompleto];
        let insumoFiltrado = [];

        insumoFiltrado = insumos.filter(function(item){
          return item.etapa === value;
        })    
        this.setState({insumos:insumoFiltrado})               
       }else{
         this.setState({insumos:[...this.state.insumosCompleto]})            
       }   
  }
  

  
  render() {
    
    let styleRemove = {cursor:'pointer'};
    if(this.props.permissao === 'Visualizar'){
      styleRemove = { display:'none'};
    }
    
    return (        
             <Container style={{padding:'10px'}}>
              <Segment clearing attached>
                <Header as='h5' floated='left' style={{marginTop:'5px',marginBottom:'0px'}}>
                  <Icon name='file'/>
                  Ordens
                </Header>                
                <Button.Group floated='right'>                  
                  < Button onClick={this.atualizaApontamentos.bind(this)} floated = 'right' size = 'mini' icon > < Icon name = 'refresh' / > < /Button>                 
                  <ExportaOrdens/>
                  <div style={styleRemove}>
                    <AdicionaApontamento dataItem={this.props.dataItem} usuario={this.props.usuario}/>
                  </div>
              </Button.Group>

              </Segment>
              <Segment clearing attached>
                  <Menu secondary>
                      <Menu.Menu position='right'>
                        <Menu.Item>
                          <Input list='lista_etapas' icon='search' placeholder='Etapa...' onChange={this.filtra.bind(this)}/>
                           <datalist id='lista_etapas'>                
                                <option value='BRASSAGEM' /> 
                                <option value='FERMENTAÇÃO' />
                                <option value='MATURAÇÃO' />
                                <option value='FILTRAÇÃO' />
                                <option value='ENVASAMENTO'/>
                           </datalist>
                        </Menu.Item>
                      </Menu.Menu>
                  </Menu>
              </Segment>

            <Segment clearing attached style={{overflowY: "auto", height: "500px", padding: "0px", overflowX: "hidden"}}>
              <Dimmer active={this.state.request} inverted>
                <Loader size='large'></Loader>
              </Dimmer>              
              <Table size='small' textAlign='center' celled structured attached>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{width:'5px'}}></Table.HeaderCell>
                      <Table.HeaderCell>Etapa</Table.HeaderCell>
                      <Table.HeaderCell>Ordem</Table.HeaderCell>                      
                      <Table.HeaderCell>Início</Table.HeaderCell>
                      <Table.HeaderCell>Fim</Table.HeaderCell>      
                      <Table.HeaderCell>Controle</Table.HeaderCell>
                      <Table.HeaderCell>Recurso</Table.HeaderCell>
                      <Table.HeaderCell>Código Produto</Table.HeaderCell>
                      <Table.HeaderCell>Produto</Table.HeaderCell>
                      <Table.HeaderCell>Bruta</Table.HeaderCell>      
                      <Table.HeaderCell>Líquida</Table.HeaderCell>    
                      <Table.HeaderCell style={styleRemove} width={1}><Icon name='remove circle' /></Table.HeaderCell>
                      <Table.HeaderCell style={styleRemove} width={1}><Icon name='edit' /></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.insumos.map(item =>
                    <Table.Row key={item.key}>
                      <Table.Cell style={{backgroundColor:item.cor}}></Table.Cell>
                      <Table.Cell>{item.etapa}</Table.Cell>
                      <Table.Cell>{item.ordem}</Table.Cell>
                      <Table.Cell>{item.inicio}</Table.Cell>                      
                      <Table.Cell>{item.fim}</Table.Cell>                      
                      <Table.Cell>{item.controle}</Table.Cell>                      
                      <Table.Cell>{item.recurso}</Table.Cell>   
                      <Table.Cell>{item.codigo_produto}</Table.Cell>   
                      <Table.Cell>{item.produto}</Table.Cell>   
                      <Table.Cell>{formatDecimal(item.quantidade_produto*1, opts)}</Table.Cell>                         
                      <Table.Cell>{formatDecimal(item.quantidade_liquida*1, opts)}</Table.Cell>                         
                      <Table.Cell style={styleRemove}>                        
                          <Modal trigger={<Icon color='red' name='remove circle' style={{cursor:'pointer'}} data-key={item.key}/>} basic size='small'>
                              <Header icon='trash alternate' content='Confirmação de deleção de item' />
                              <Modal.Content>
                                <p>
                                  Deseja realmente deletar este item?
                                </p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button primary onClick={this.remove.bind(this,item.key)}>
                                  <Icon name='checkmark' /> Sim
                                </Button>
                              </Modal.Actions>
                            </Modal>  
                      </Table.Cell>
                      <Table.Cell style={styleRemove}><EditaApontamento  usuario={this.props.usuario} dataItem={item} dataConsumo={this.state.consultaConsumos} /></Table.Cell>
                    </Table.Row>
                  )}
                  </Table.Body>                  
                </Table>
              </Segment>
            </Container>                  
    );
  }
}
                                  
export default TabelaInsumos;
