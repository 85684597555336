import React, { Component } from 'react';
import {Button,Modal,Form,Icon, Input, TextArea} from 'semantic-ui-react';
import { DateInput, TimeInput, DateTimeInput, DatesRangeInput } from 'semantic-ui-calendar-react';

import PubSub from 'pubsub-js';
const random = require('../utilitarios/chave');

var request = require("request");

var dedupe = require('dedupe');

class AdicionaRequisicoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: random(50),      
      data:'',
      codigo:'',
      codigo_requisicao:'',
      material:'',
      um:'',
      quantidade:'',
      area:'',
      key_usuario:this.props.dataItem,
      lista_codigos:[],
      lista_materiais:[],
      lista_descricoes_materiais:[],
      comentario:'',
      processando:false
    }

    this.handleClick = this.handleClick.bind(this);
  }

    componentDidMount(){   
   // this.nameInput.focus();

    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/material',
      headers: {} 
    };

    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)    
        
        for (var o in a){
          delete a[o].id
        }
      
        var b = [...a];
        b = b.map(function(item){return item.codigo});
        b = dedupe(b);
        
        
        var c = [...a];
        c = c.map(function(item){return item.material});
        c = dedupe(c);
        
        this.setState({lista_descricoes_materiais:c},function(){
          console.log(this.state.lista_descricoes_materiais)
          }.bind(this))
        
        this.setState({lista_codigos:b})
        this.setState({lista_materiais:a});      
      }
    }.bind(this));

  }
  
  
  handleClick() {
    
   let self = this.state;   

    this.setState({
      key: random(50)
    });
   // let self = this.state;   

    this.setState({processando:true});
    var options = {
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/apontamento/requisicoes',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);
      console.log(body);
      this.setState({processando:false});
      PubSub.publish('add_requisicoes', body);
    }.bind(this));    

    this.setState({
      key: random(50),
      data:'',
      codigo_requisicao:'',
      codigo:'',
      material:'',
      um:'',
      quantidade:'',
      comentario:'',
      area:'',
      key_usuario:this.props.dataItem
    })
    
   // PubSub.publish('add_insumo', self);
    
  }

 altera(e,{name,value}) {
    this.setState({
      [name]: (name === 'data') ? value.replace(/-/g,'/') : value     
    },function(){
      let lista_codigos_Filtrada = [];
      if(name === 'codigo'){
        lista_codigos_Filtrada = this.state.lista_materiais.filter(function(item){
          return item.codigo === this.state.codigo;
        }.bind(this))
        
        if(lista_codigos_Filtrada.length>=1){
          this.setState({material:lista_codigos_Filtrada[0].material,um:lista_codigos_Filtrada[0].um})
        }
      }     
      
      if(name === 'material'){
        lista_codigos_Filtrada = this.state.lista_materiais.filter(function(item){
          return item.material === this.state.material;
        }.bind(this))
        
        if(lista_codigos_Filtrada.length>=1){
          this.setState({codigo:lista_codigos_Filtrada[0].codigo,um:lista_codigos_Filtrada[0].um})
        }
      }     
 
    }.bind(this)  )  
  }
  
  render() {
    return ( <Modal style={{width:'96%'}} trigger = { < Button primary floated = 'right' size = 'mini' icon > < Icon name = 'plus' / > < /Button>} centered={false} closeIcon> 
               <Modal.Header >
                  Requisição de Materiais 
               < /Modal.Header> 
                <Modal.Content >
        <Form >        
         <Form.Group widths = 'equal' >
            <Form.Input width={2} type = "number" fluid label = 'Requisição' name='codigo_requisicao' placeholder = 'Requisição' value = {this.state.codigo_requisicao} onChange = {this.altera.bind(this)}/>               
            <Form.Field width={4}>
              <label>Código</label>
              <Input list='list_codigos' type = "text" fluid name='codigo' placeholder = 'Código' value = {this.state.codigo} onChange = {this.altera.bind(this)}/> 
               <datalist id='list_codigos'>
               {this.state.lista_codigos.map(item =>
                  <option value={item}/>
                )}
               </datalist>
            </Form.Field>
            <Form.Field width={4}>
              <label>Material</label>
              <Input list='list_descricoes' width={8} type = "text" fluid name='material'  placeholder = 'Material' value = {this.state.material} onChange = {this.altera.bind(this)}/>                         
               <datalist id='list_descricoes'>
               {this.state.lista_descricoes_materiais.map(item =>
                  <option value={item}/>
                )}
               </datalist>
              </Form.Field>
              <Form.Input width={2} type = "text" fluid label = 'UM' readOnly placeholder = 'UM' name='um' value = {this.state.um} onChange = {this.altera.bind(this)}/>           
          < /Form.Group >
          <Form.Group widths = 'equal' >
             <Form.Field width={2}>
              <label>Data</label>           
                <DateInput
                    name="data"
                    value = {this.state.data} 
                    placeholder="Data"                    
                    iconPosition="left"
                    onChange = {this.altera.bind(this)}
                    />
             </Form.Field>
             <Form.Field width={8}>
              <label>Área</label>             
              <Input type = "text" list='list_areas' fluid name='area'  placeholder = 'Área' value = {this.state.area} onChange = {this.altera.bind(this)}/>                                       
              <datalist id='list_areas'>                    
                    <option value="BRASSAGEM"/>
                    <option value="ADEGA"/>
                    <option value="FILTRAÇÃO "/>
                    <option value="ETA"/>
                    <option value="ETDI"/>
                    <option value="LABORATÓRIO"/>                    
                    <option value="LINHA DE LATAS"/>
                    <option value="LINHA DE GARRAFAS"/>
                    <option value="CHOPP"/>
                    <option value="UTILIDADES"/>
               </datalist>
            </Form.Field>
            <Form.Input width={4} type = "number" fluid label = 'Quantidade' name='quantidade' placeholder = 'Quantidade' value = {this.state.quantidade} onChange = {this.altera.bind(this)}/>               
          < /Form.Group >
          <Form.Group style={{marginLeft:'0px', marginRight:'0px'}}>
            <TextArea placeholder='Adicione aqui alguma informação relevante para esta movimentação.' name='comentario' value={this.state.comentario} onChange = {this.altera.bind(this)} />
          </Form.Group>
          <Button loading={this.state.processando} disabled={this.state.processando}  onClick = {this.handleClick} primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button> 
        < /Form > 
        </Modal.Content> 
    < /Modal >
      );
    }
  }

  export default AdicionaRequisicoes;
