import React, { Component } from 'react';
import {Button,Modal,Form,Icon, Input, Table, Header} from 'semantic-ui-react';
import { DateInput, TimeInput, DateTimeInput, DatesRangeInput } from 'semantic-ui-calendar-react';

import PubSub from 'pubsub-js';
const random = require('../utilitarios/chave');

var request = require("request");

var dedupe = require('dedupe');

class AdicionaInsumo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: random(50),
      etapa:'',
      ordem:'',
      inicio:'',
      fim:'',
      controle:'',
      recurso:'',
      codigo_produto:'',
      produto:'',         
      quantidade_liquida:'',
      quantidade_produto:'',   
      mosto_basico:'',
      key_usuario:this.props.usuario,
      valor_real_apontamento:'',
      estruturas:[],
      estruturaFiltrada:[],
      produtos:[],
      processando:false
      
    }
    
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(){   
   // this.nameInput.focus();

    var options = { method: 'GET',
      url: 'https://inteligenciaindustrial.com/apontamento/estrutura',
      headers: {} 
    };

    request(options, function (error, response, body) {
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)    
        
        for (var o in a){
          delete a[o].id
        }
      
        var b = [...a];
        b = b.map(function(item){return item.codigo_produto});
        b = dedupe(b);
        
        console.log(a)
        console.log(b)
        
        this.setState({produtos:b})
        this.setState({estruturas:a});      
      }
    }.bind(this));

  }
  
  handleClick() {
   
    this.setState({
      key: random(50)
    });
   // let self = this.state;   
    this.setState({processando:true});
        
        var self = this.state;   
            var options = {
                method: 'POST',
                url: 'https://inteligenciaindustrial.com/apontamento/ordem',
                headers: {              
                  'content-type': 'application/json'
                },
                body: self,
                json: true
              };

              request(options, function(error, response, body) {
                if (error) throw new Error(error);
                console.log(body);
                PubSub.publish('add_ordem', body);
                 this.setState({ordem:''})
                this.setState({processando:false});
              }.bind(this));         
  
    


    let estruturaParaGravacao = this.state.estruturaFiltrada
    for(var i in estruturaParaGravacao){
      estruturaParaGravacao[i].key_ordem = this.state.key;
      estruturaParaGravacao[i].key_usuario = this.state.key_usuario;
      estruturaParaGravacao[i].key =  random(50);
      estruturaParaGravacao[i].codigo_insumo = estruturaParaGravacao[i].codigo;
      estruturaParaGravacao[i].quantidade_padrao = estruturaParaGravacao[i].quantidade;
    }

    this.setState({estruturaFiltrada:estruturaParaGravacao})
    
    
    
    for(var j in this.state.estruturaFiltrada){
      
    var options2 = {
      method: 'POST',
      url: 'https://inteligenciaindustrial.com/apontamento/consumo',
      headers: {              
        'content-type': 'application/json'
      },
      body: this.state.estruturaFiltrada[j],
      json: true
    };
    
    request(options2, function(error, response, body) {
      if (error) throw new Error(error);
      console.log(body);     
    }); 
      
    }    
   this.setState({
      key: random(50),
      etapa:'',
      ordem:'',
      inicio:'',
      fim:'',
      controle:'',
      recurso:'',
      codigo_produto:'',
      produto:'',  
      quantidade_liquida:'',
      quantidade_produto:'',
      mosto_basico:'',
      estruturaFiltrada:[]      
    })
  }

  Right(str, n){
      if (n <= 0)
         return "";
      else if (n > String(str).length)
         return str;
      else {
         var iLen = String(str).length;
         return String(str).substring(iLen, iLen - n);
      }
  }

  
  altera(e,{name,value}) {
    this.setState({
      [name]: (name === 'inicio' || name === 'fim') ? value.replace(/-/g,'/') : value     
    },function(){
      let estruturaFiltrada = [];
      if(name === 'codigo_produto'){
        estruturaFiltrada = this.state.estruturas.filter(function(item){
          return item.codigo_produto === this.state.codigo_produto;
        }.bind(this))
        
        for(var i in estruturaFiltrada){
          estruturaFiltrada[i].quantidade_real = '';
          
          if(this.state.etapa === 'ENVASAMENTO'){
            estruturaFiltrada[i].quantidade_por_producao = Math.round(this.state.quantidade_liquida * estruturaFiltrada[i].quantidade*1000000)/1000000;   
          }else{
            estruturaFiltrada[i].quantidade_por_producao = Math.round(this.state.quantidade_produto * estruturaFiltrada[i].quantidade*1000000)/1000000;
          }
        }        
        
        if(estruturaFiltrada.length>=1){
          this.setState({produto:estruturaFiltrada[0].produto})
        }
        
        this.setState({estruturaFiltrada:estruturaFiltrada})
      }     
      
      if(name === 'quantidade_liquida' && this.state.etapa === 'ENVASAMENTO'){
        estruturaFiltrada = this.state.estruturaFiltrada;
        
        for(var y in estruturaFiltrada){
          //estruturaFiltrada[i].quantidade_real = 0;
          estruturaFiltrada[y].quantidade_por_producao = Math.round(this.state.quantidade_liquida * estruturaFiltrada[y].quantidade*1000000)/1000000;
        }        
        
        if(estruturaFiltrada.length>=1){
          this.setState({produto:estruturaFiltrada[0].produto})
        }
        
        this.setState({estruturaFiltrada:estruturaFiltrada})
      }else if(name === 'quantidade_produto' && this.state.etapa !== 'ENVASAMENTO'){
        estruturaFiltrada = this.state.estruturaFiltrada
        
        for(var o in estruturaFiltrada){
          //estruturaFiltrada[i].quantidade_real = 0;
          estruturaFiltrada[o].quantidade_por_producao = Math.round(this.state.quantidade_produto * estruturaFiltrada[o].quantidade*1000000)/1000000;
        }        
        
       /* if(estruturaFiltrada.length>=1){
          this.setState({produto:estruturaFiltrada[0].produto})
        }
        */
        
        this.setState({estruturaFiltrada:estruturaFiltrada})
      }
    }.bind(this))  
  }
  
  atualizaValor(e,{name,value}){
   
   let estado = this.state.estruturaFiltrada;      
      for (var i in estado) {
        if (estado[i].key === name) {
            estado[i].quantidade_real = this.state.valor_real_apontamento;
            break;
         }
      }
      this.setState({estruturaFiltrada:estado})
      this.setState({valor_real_apontamento:''})
  }
  
render() {
    return (      
      <Modal style={{width:'95%'}} trigger = { < Button primary floated = 'right' size = 'mini' icon > < Icon name = 'plus' / > < /Button>} centered={false} closeIcon> 
               <Modal.Header > Apontamento < /Modal.Header> 
                <Modal.Content scrolling>
                <Form >        
                 <Form.Group widths = 'equal' >
                     <Form.Field width={4}>
                        <label>Etapas</label>
                        <Input list='etapas'type = "text" name='etapa' fluid placeholder='Etapa' value = {this.state.etapa} onChange = {this.altera.bind(this)}/>               
                        <datalist id='etapas'>                
                          <option value='BRASSAGEM' />
                          <option value='FERMENTAÇÃO' />
                          <option value='MATURAÇÃO' />
                          <option value='FILTRAÇÃO' />
                          <option value='ENVASAMENTO'/>
                        </datalist>
                      </Form.Field>

                      <Form.Field width={4}>
                        <label>Código Produto</label>
                        <Input list='produtos' type = "text" name='codigo_produto' fluid placeholder = 'Código Produto' value = {this.state.codigo_produto} onChange = {this.altera.bind(this)}/> 
                        <datalist id='produtos'>
                          {this.state.produtos.map(item =>
                            <option value={item}/>
                          )}
                        </datalist>
                      </Form.Field>

              <Form.Input width={5} type = "text" style={{width:'300px'}} readOnly name='produto' fluid label = 'Produto'  placeholder = 'Produto' value = {this.state.produto} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={4} type = "number" name='quantidade_produto' fluid label = 'Bruta'  placeholder = 'Bruta' value = {this.state.quantidade_produto} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={4} type = "number" name='quantidade_liquida' fluid label = 'Líquida'  placeholder = 'Líquida' value = {this.state.quantidade_liquida} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={4} type = "number" name='mosto_basico' fluid label = 'Mosto Básico'  placeholder = 'Mosto Básico' value = {this.state.mosto_basico} onChange = {this.altera.bind(this)}/>           
              
          < /Form.Group >
          <Form.Group widths = 'equal' >     
            <Form.Field width={2}>
              <label>Início</label>           
                <DateTimeInput
                    name="inicio"
                    value = {this.state.inicio} 
                    placeholder="Início"                    
                    iconPosition="left"
                    onChange = {this.altera.bind(this)}
                    />
             </Form.Field>
             <Form.Field width={2}>
              <label>Fim</label>           
                <DateTimeInput
                    name="fim"
                    value = {this.state.fim} 
                    placeholder="Fim"                    
                    iconPosition="left"
                    onChange = {this.altera.bind(this)}
                    />
             </Form.Field>
              <Form.Input width={2} type = "text" readOnly name='ordem' fluid label = 'Ordem'  placeholder = 'Ordem' value = {this.state.ordem} onChange = {this.altera.bind(this)}/>                              
              <Form.Input width={2} type = "text" name='controle' fluid label = 'Controle'  placeholder = 'Controle' value = {this.state.controle} onChange = {this.altera.bind(this)}/>                              
          < /Form.Group >
          <Form.Group widths = 'equal' >                          
              <Form.Input width={2} type = "text" name='recurso' fluid label = 'Recurso'  placeholder = 'Recurso' value = {this.state.recurso} onChange = {this.altera.bind(this)}/>                                            
          < /Form.Group >
          
        < /Form >
        <Table size='small' textAlign='center' celled structured attached>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Código</Table.HeaderCell>
                      <Table.HeaderCell>Insumo</Table.HeaderCell>                      
                      <Table.HeaderCell>UM</Table.HeaderCell>      
                      <Table.HeaderCell>Quantidade Padrão para Unidade Básica</Table.HeaderCell>                                            
                      <Table.HeaderCell>Quantidade Padrão</Table.HeaderCell>                                            
                      <Table.HeaderCell>Quantidade Real</Table.HeaderCell>                                                                  
                      <Table.HeaderCell width={1}><Icon name='edit' /></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.estruturaFiltrada.map(item =>
                    <Table.Row key={item.key}>
                      <Table.Cell>{item.codigo}</Table.Cell>
                      <Table.Cell>{item.insumo}</Table.Cell>
                      <Table.Cell>{item.um}</Table.Cell>                      
                      <Table.Cell>{item.quantidade}</Table.Cell>                                            
                      <Table.Cell>{item.quantidade_por_producao}</Table.Cell>                                            
                      <Table.Cell>{item.quantidade_real}</Table.Cell>                                            
                      <Table.Cell>
                         <Modal size='small' trigger={<Icon color='orange' name='edit' style={{cursor:'pointer'}} />} closeIcon>
                            <Modal.Header>Ajustar Quantidade Real</Modal.Header>
                            <Modal.Content>
                              <Header as='h5'>{item.codigo} - {item.insumo}:  {item.quantidade_por_producao} {item.um}</Header>
                              <Form>                                
                                  <Form.Input autoFocus data-key={item.key} name='valor_real_apontamento' value={this.state.valor_real_apontamento} type='number' fluid label = 'Valor Real' placeholder = 'Valor Real' onChange = {this.altera.bind(this)}/>                                
                              </Form >
                            </Modal.Content>
                            <Modal.Actions>
                              <Button icon='check' name={item.key} primary content='Confirmar' labelPosition = 'left'  onClick={this.atualizaValor.bind(this)} />
                            </Modal.Actions>
                          </Modal>                        
                      </Table.Cell>
                    </Table.Row>
                  )}
                  </Table.Body>                  
             </Table>             
        </Modal.Content> 
        <Modal.Actions>
            <Button style={{margin:'10px'}} onClick = {this.handleClick} loading={this.state.processando} disabled={this.state.processando} floated='right' primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button> 
         </Modal.Actions>
        </Modal > 
      );
    }
  }

  export default AdicionaInsumo;
