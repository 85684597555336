import React, {
  Component
} from 'react';
import {
  Button,
  Modal,
  Form,  
  Icon
} from 'semantic-ui-react';

import PubSub from 'pubsub-js';

var request = require("request");

class EditaMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {processando:false}

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {

    let self = this.state;   
    
    this.setState({processando:true});
    
    //let self = this.state;   

    var options = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/apontamento/material',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);      
      PubSub.publish('edit_material', self);
      this.setState({processando:false});
    }.bind(this));    

    //PubSub.publish('edit_insumo', self);
  }

 altera(e,{name,value}) {
    this.setState({
      [name]: e.target.value      
    })  
  }
  
atualizaEstado() {
    let self = this.props.dataItem;
    this.setState(self);
  }
  render() {
    return ( <Modal size = {'fullscreen'} trigger = { <Icon color='orange' name='edit' onClick={this.atualizaEstado.bind(this)} style={{cursor:'pointer'}} />} centered={false} closeIcon> 
               <Modal.Header > Materiais < /Modal.Header> 
                <Modal.Content >
        <Form >                  
          <Form.Group widths = 'equal' >
              <Form.Input width={4} type = "text" fluid name='codigo' label = 'Código Produto' placeholder = 'Código Produto' value = {this.state.codigo} onChange = {this.altera.bind(this)}/> 
              <Form.Input width={8} type = "text" fluid name='material' label = 'Produto'  placeholder = 'Produto' value = {this.state.material} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={2} type = "text" fluid label = 'Tipo' name='tipo'  placeholder = 'Tipo' value = {this.state.tipo} onChange = {this.altera.bind(this)}/>           
              <Form.Input width={2} type = "text" fluid name='um' label = 'UM'  placeholder = 'UM' value = {this.state.um} onChange = {this.altera.bind(this)}/>           
          < /Form.Group >
          <Button loading={this.state.processando} disabled={this.state.processando} onClick = {this.handleClick} primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button>           
        < /Form > 
        </Modal.Content> 
    < /Modal >
      );
    }
  }

  export default EditaMaterial;
