import React , { Component } from 'react'
import { Button, Form, Icon, Grid, Header, Image, Message, Segment, Transition, Loader } from 'semantic-ui-react'


import logo from '../img/getdataind_logo.png'
import logo_ccip from '../img/ccip_logo.png'

import PubSub from 'pubsub-js';

var request = require("request");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { visible:false ,visibleSenha:'none',visiblemsg:'none', usuario:'', senha:'',activeLogin:''}
  }

  componentDidMount(){  
    
    var myVar = setInterval(function(){
      this.setState({visible:true});
      stopColor()
    }.bind(this), 1000);
    
    function stopColor() {
        clearInterval(myVar);
    }
  }
  
  submeteAutenticacao(){
    
    this.setState({activeLogin:'active'});
    
     var options = { method: 'POST',
        url: 'https://inteligenciaindustrial.com/apontamento/login',
        headers: {              
        'content-type': 'application/json'
        },
        body: {"usuario":this.state.usuario,"senha":this.state.senha},
        json: true
      };
     
    request(options, function (error, response, body) {
      
      if (error){
        console.log(error)
        this.setState({activeLogin:''});
      }else{
        this.setState({activeLogin:''});
        if(body.mensagem === "autenticacao invalida"){
          this.setState({visibleSenha:''})  
        }else if(body.mensagem === "autenticacao valida"){
          this.setState({visibleSenha:'none'})  
          PubSub.publish('autenticacao', { validado:true, usuario:body.usuario, permissao:body.permissao });
        }
        
        console.log(body)
      }            
    }.bind(this));

    
  }
  
 handleDismiss(){
    this.setState({ visiblemsg: 'none' })
  }
  
  handleDismissOn(){
    this.setState({ visiblemsg: '' })
  }

  
  alteraUsuario(e){    
    this.setState({usuario:e.target.value})
  }
  alteraSenha(e){    
    this.setState({senha:e.target.value})
  }
  
  render() {
  
  const { visible } = this.state
  return(    
        <Transition.Group animation='fade down' duration='3000'>
          {visible && 
          <div className='login-form'>    
            <style>{`
              body > div,
              body > div > div,
              body > div > div > div.login-form {
                height: 100%;
              }
            `}</style>
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>              
                  <Grid.Column style={{ maxWidth: 350}}>
                   <Header as='h2' color='teal' textAlign='center'>
                    <Image src={logo} style={{width:'400px'}} /> 
                  </Header>
                  <Form size='small'>
                    <Loader active={this.state.activeLogin}/>
                    <Segment stacked>
                      <Form.Input fluid icon='user' value={this.state.user} iconPosition='left' placeholder='Usuário' onChange={this.alteraUsuario.bind(this)} />
                      <Form.Input fluid icon='lock' value={this.state.senha} iconPosition='left' placeholder='Senha' type='password'  onChange={this.alteraSenha.bind(this)}/>                      
                      <Button color='black' fluid size='large' onClick={this.submeteAutenticacao.bind(this)} icon labelPosition='right'>
                        ENTRAR
                        <Icon name='sign in alternate'/>
                      </Button>
                    </Segment>
                  </Form>
                  
                  <Message>
                    Esqueceu usuário e senha? <a style={{cursor:'pointer'}} onClick={this.handleDismissOn.bind(this)}>Clique aqui!</a>
                  </Message>
                  <Message onDismiss={this.handleDismiss.bind(this)} style={{display:this.state.visiblemsg}} 
                    header='Usuário e senha' 
                    content='Caso tenha esquecido ou não possua usuário ou senha, mande um e-mail para: bruiz@itucentral.com.br.'
                  />
                   <Message  color='red' style={{display:this.state.visibleSenha}} >
                    Usuário ou seha inválidos! Tente novamente.
                  </Message>
                  <Header as='p' color='black' style={{fontSize:'10px'}} textAlign='center'>
                    Desenvolvido por 
                    <Image src={logo_ccip} style={{width:'75px'}} /> 
                  </Header>
                  <Header as='p' color='black' style={{fontSize:'8px'}} textAlign='center'>
                    v20200925.001
                  </Header>
                  
                </Grid.Column>
     
            </Grid>    
          </div>}
      </Transition.Group>    
  )
}
  }

export default Login