import React, { Component } from 'react';

//import * as moment from 'moment';
//import 'moment/locale/pt-br';

import './App.css';

import PubSub from 'pubsub-js';


import Login from './componentes/autenticacao/login';
import Principal from './componentes/principal/principal';


//moment.locale('pt-BR');
//console.log(moment.locale()); // pt-BR


class App extends Component {
  constructor(props){
    super(props);
    this.state = {component:'login',usuario:'', permissao:''}
  }
  
  componentDidMount(){      
    PubSub.subscribe('autenticacao', function (topic, data) {                
      if(data.validado === true){
        this.setState({component:'principal', usuario:data.usuario, permissao:data.permissao})
      }else if(data.validado === false){
        this.setState({component:'login'})
      }
    }.bind(this));        
  }
  
  render() {
          if (this.state.component === 'login') {
            return (<Login/>);
          } else if (this.state.component === 'principal') {
            return (<Principal usuario={this.state.usuario} permissao={this.state.permissao} />);
          }    
  }
}

export default App;
