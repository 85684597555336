import React, { Component } from 'react';
import { Icon, Button, Loader, Grid, Container, Segment, Table, Header, Select, Menu, Dimmer } from 'semantic-ui-react';
import exportFromJSON from 'export-from-json';

import Detalhe from './detalhe';

var dedupe = require('dedupe');

var request = require("request");

const formatCurrency = require('format-currency');
//let opts = { locale: 'de-DE' };

const formatDecimal = require('format-decimal');
var opts =  {
            decimal   : ',',
            precision : 4,
            thousands : '.'
        }

// Component which contains the dynamic state for the chart
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {ano:'',mes:'', requisicoes:[],tempo:[],saldos:[],consumos:[],dataProvider:[],consolidado:[],anos:[{key:'2018',value:'2018',text:'2018'},
                                                                                           {key:'2019',value:'2019',text:'2019'},
                                                                                           {key:'2020',value:'2020',text:'2020'},
                                                                                           {key:'2021',value:'2021',text:'2021'}]
                  ,meses:[{key:'JANEIRO',value:'JANEIRO',text:'JANEIRO'},
                                                                                            {key:'FEVEREIRO',value:'FEVEREIRO',text:'FEVEREIRO'},
                                                                                            {key:'MARÇO',value:'MARÇO',text:'MARÇO'},
                                                                                            {key:'ABRIL',value:'ABRIL',text:'ABRIL'},
                                                                                            {key:'MAIO',value:'MAIO',text:'MAIO'},
                                                                                            {key:'JUNHO',value:'JUNHO',text:'JUNHO'},
                                                                                            {key:'JULHO',value:'JULHO',text:'JULHO'},
                                                                                            {key:'AGOSTO',value:'AGOSTO',text:'AGOSTO'},
                                                                                            {key:'SETEMBRO',value:'SETEMBRO',text:'SETEMBRO'},
                                                                                            {key:'OUTUBRO',value:'OUTUBRO',text:'OUTUBRO'},
                                                                                            {key:'NOVEMBRO',value:'NOVEMBRO',text:'NOVEMBRO'},
                                                                                            {key:'DEZEMBRO',value:'DEZEMBRO',text:'DEZEMBRO'}], active:''};
  }

  componentDidMount() {    
    //this.handleButtonClick();
    //this.tempo();
  }

  componentWillUnmount() {
    
  }

saldos() {     
     var options = { method: 'GET',
        url: 'https://inteligenciaindustrial.com/apontamento/relatorioestoqueindustrial/saldos',
        headers: {} 
      };
    
    request(options, function (error, response, body) {     
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)                        
        this.setState({saldos:a}, this.consolida.bind(this));        
     
      }            
    }.bind(this));
  }
  
  
  requisicoes() {     
     var options = { method: 'GET',
        url: 'https://inteligenciaindustrial.com/apontamento/relatorioestoqueindustrial/requisicoes',
        headers: {} 
      };
    
    request(options, function (error, response, body) {     
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)                        
        this.setState({requisicoes:a}, this.saldos.bind(this));
     
      }            
    }.bind(this));
  }
  
  consumos() {     
     var options = { method: 'GET',
        url: 'https://inteligenciaindustrial.com/apontamento/relatorioestoqueindustrial/consumos',
        headers: {} 
      };
    
    request(options, function (error, response, body) {     
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body)                        
        this.setState({consumos:a});     
        this.requisicoes()
      }            
    }.bind(this));
  }
  
  tempo() {
     this.setState({active:'active'})
    this.setState({consolidado:[]});     
     var options = { method: 'GET',
        url: 'https://inteligenciaindustrial.com/apontamento/relatorioestoqueindustrial/itens/'+this.state.ano+'&'+this.state.mes,
        headers: {} 
      };
    
    request(options, function (error, response, body) {     
      if (error){
        console.log(error)
      }else{
        var a = JSON.parse(body);                        
        this.setState({tempo:a});
        this.consumos()
      }            
    }.bind(this));
  }
  
  consolida(){
   let tempo = this.state.tempo; 
   let requisicoes = this.state.requisicoes; 
   let consumos = this.state.consumos; 
    
   for(var i in tempo){
     tempo[i].requisicoes = 0;
     for(var u in requisicoes){       
       if(tempo[i].ID_TEMPO === requisicoes[u].id_tempo && tempo[i].codigo === requisicoes[u].codigo){         
         tempo[i].requisicoes += requisicoes[u].quantidade*1;
       }
     }     
   }
    
   for(var x in tempo){
     tempo[x].consumos=0;
     for(var y in consumos){
       if(tempo[x].ID_TEMPO === consumos[y].id_tempo && tempo[x].codigo === consumos[y].codigo){         
         tempo[x].consumos += consumos[y].quantidade_real*1;
       }       
     }          
   }
    
    this.setState({dataProvider:tempo}, function(){
      let rd = this.state.dataProvider;
      let rdStatic = this.state.dataProvider;
      let rdSaldos = this.state.saldos;
      console.log(rd)
      rd = rd.map(function(item){
        return {'ANO':item.ANO,'MES':item.MES,'codigo':item.codigo,'insumo':item.insumo}
      })
      
      rd = dedupe(rd);      
      for (var o in rd){
        
          rd[o].consumos = 0;
          rd[o].requisicoes = 0;
          for(var t in rdStatic){
            if(rd[o].codigo === rdStatic[t].codigo){              
              if(rdStatic[t].consumos !== undefined){                
                rd[o].consumos += Math.round(rdStatic[t].consumos*1*10000)/10000;
              }
               
              if(rdStatic[t].requisicoes !== undefined){
                rd[o].requisicoes += Math.round(rdStatic[t].requisicoes*1*10000)/10000;
              }
               }
          }
        }
      
      for(var u in rd){
        rd[u].diferenca = rd[u].requisicoes - rd[u].consumos;             
      }
      
      
      for(var c in rd){
        
        for(var l in rdSaldos){
          if(rdSaldos[l].ANO === rd[c].ANO && rdSaldos[l].MES === rd[c].MES && rdSaldos[l].codigo === rd[c].codigo){
            
            rd[c].saldo = rdSaldos[l].saldo; 
            rd[c].diferenca = rd[c].diferenca + rd[c].saldo*1;
            
            if(rd[c].diferenca < 0){
              rd[c].status = 'negative';
            }else{
             rd[c].status = '';
            } 
            
             }          
        }   
      }
      
      
      this.setState({consolidado:rd});
      
      console.log(rd)
      
    }.bind(this));
    
    this.setState({active:''});
  }
  
  alteraMes(e, {
    value,
    text
  }) {
    this.setState({
      mes: value
    })
  }
  
  alteraAno(e, {
    value,
    text
  }) {
    this.setState({
      ano: value
    })
  }
  
  exportarOrdens(){
    
    let data = this.state.dataProvider ;
    let fileName = 'movimentos';
    let exportType = 'xls';
    
    for(var t in data){
      if(data[t].consumos !== undefined){ 
        data[t].consumos = data[t].consumos.toString().replace('.',',');       
      }
      
      if(data[t].requisicoes !== undefined){        
        data[t].requisicoes = data[t].requisicoes.toString().replace('.',',');
      }
      
    }
    
    exportFromJSON({ data, fileName, exportType })
    
  }
  
  render() {
 
    
    return (
       <Container style={{padding:'10px'}}>  
        <Segment clearing attached='top'>
          <Header as='h5' floated='left' style={{marginTop:'5px',marginBottom:'0px'}}>
            <Icon name='chart bar'/>
            Relatório Estoque Industrial
          </Header>              
         </Segment>
         <Segment clearing attached>
                  <Menu secondary>
                      <Menu.Menu position='right'>
                        <Menu.Item>
                          <Select placeholder='Selecione um ano' options={this.state.anos} style={{width:'50px', marginRight: '10px'}} onChange={this.alteraAno.bind(this)}/>
                          <Select placeholder='Selecione um mês' options={this.state.meses} style={{width:'250px', marginRight: '10px'}} onChange={this.alteraMes.bind(this)}/>
                          <Button onClick={this.tempo.bind(this)} primary floated = 'right' icon style={{marginRight: '10px'}} ><Icon name='search'/> Pesquisar </Button>  
                          <Button onClick={this.exportarOrdens.bind(this)} floated = 'right' size = 'mini' icon > < Icon name = 'file excel' / > < /Button>
                        </Menu.Item>
                      </Menu.Menu>
                  </Menu>
         </Segment>
            <Dimmer active={this.state.active} inverted>
              <Loader size='large'></Loader>
            </Dimmer> 
            <Grid columns={1}>            
              <Grid.Row>
                <Grid.Column>                  
                 <Table size='small' textAlign='center' celled structured attached selectable>
                  <Table.Header>
                    <Table.Row>                                            
                      <Table.HeaderCell>Código</Table.HeaderCell>                      
                      <Table.HeaderCell>Descrição</Table.HeaderCell>
                      <Table.HeaderCell>Saldo</Table.HeaderCell>      
                      <Table.HeaderCell>Consumos</Table.HeaderCell>      
                      <Table.HeaderCell>Requisições</Table.HeaderCell>                      
                      <Table.HeaderCell>Diferença</Table.HeaderCell>                      
                      <Table.HeaderCell><Icon disabled name='window restore outline' /></Table.HeaderCell>                      
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {this.state.consolidado.map(item =>
                    <Table.Row key={item.codigo}>                      
                      <Table.Cell>{item.codigo}</Table.Cell>
                      <Table.Cell>{item.insumo}</Table.Cell>                                                                                        
                      <Table.Cell>{formatDecimal(((item.saldo === undefined)? 0: item.saldo*1), opts)}</Table.Cell>   
                      <Table.Cell>{formatDecimal(item.consumos*1, opts)}</Table.Cell>                         
                      <Table.Cell>{formatDecimal(item.requisicoes*1, opts)}</Table.Cell>                         
                      <Table.Cell negative={item.status} >{formatDecimal(item.diferenca*1, opts)}</Table.Cell>                       
                      <Table.Cell><Detalhe dataCodigo={item.codigo} dataProvider={this.state.dataProvider}/></Table.Cell>                       
                    </Table.Row>
                  )}
                  </Table.Body>                  
                </Table>                  
                </Grid.Column>          
            </Grid.Row>
          </Grid>
    </Container>
    );
  }
}

export default App;
