import React, {
  Component
} from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  Icon
} from 'semantic-ui-react';

import PubSub from 'pubsub-js';

var request = require("request");

class EditaInsumo extends Component {
  constructor(props) {
    super(props);
    this.state = {processando:false}

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {

    let self = this.state;   
    
    this.setState({processando:true});
    
    //let self = this.state;   

    var options = {
      method: 'PUT',
      url: 'https://inteligenciaindustrial.com/apontamento/estrutura',
      headers: {              
        'content-type': 'application/json'
      },
      body: self,
      json: true
    };

    request(options, function(error, response, body) {
      if (error) throw new Error(error);      
      PubSub.publish('edit_estrutura', self);
      this.setState({processando:false});
    }.bind(this));    

    //PubSub.publish('edit_insumo', self);
  }

  alteraData(e) {
    this.setState({
      data: e.target.value      
    }) 
      
    
  }
  
  alteraEtapa(e) {
    this.setState({
      etapa: e.target.value      
    })
    
  }
  
  alteraCodigo(e) {
    this.setState({
      codigo: e.target.value      
    })    
    
  }

  alteraInsumo(e) {
    this.setState({
      insumo: e.target.value      
    })    
    
  }
  
  alteraQuantidade(e) {
    this.setState({
      quantidade: e.target.value      
    })    
    
  }
  
  alteraUM(e) {
    this.setState({
      um: e.target.value      
    })    
    
  }
  
    
alteraCodigoProduto(e){
      this.setState({
      codigo_produto: e.target.value      
    })  
  
}
alteraProduto(e){
      this.setState({
      produto: e.target.value      
    })  
  
} 

alteraOrdem(e){
      this.setState({
      ordem: e.target.value      
    })  
  
}
alteraControle(e){
      this.setState({
      controle: e.target.value      
    })  
  
}

alteraQuantidadeProduto(e){
      this.setState({
      quantidade_produto: e.target.value      
    })    
}
atualizaEstado() {
    let self = this.props.dataItem;
    this.setState(self);
  }
  render() {
    return ( <Modal size = {'fullscreen'} trigger = { <Icon color='orange' name='edit' onClick={this.atualizaEstado.bind(this)} style={{cursor:'pointer'}} />} centered={false} closeIcon> 
               <Modal.Header > Altera Estrutura < /Modal.Header> 
                <Modal.Content >
        <Form >                  
          <Form.Group widths = 'equal' >
              <Form.Input width={4} type = "text" fluid label = 'Código Produto' placeholder = 'Código Produto' value = {this.state.codigo_produto} onChange = {this.alteraCodigoProduto.bind(this)}/> 
              <Form.Input width={10} type = "text" fluid label = 'Produto'  placeholder = 'Produto' value = {this.state.produto} onChange = {this.alteraProduto.bind(this)}/>           
              <Form.Input width={2} type = "text" fluid label = 'Quantidade'  placeholder = 'Quantidade' value = {this.state.quantidade_produto} onChange = {this.alteraQuantidadeProduto.bind(this)}/>           
          < /Form.Group >
          <Form.Group widths = 'equal' >              
             <Form.Field width={2}>
              <label>Etapas</label>
              <Input list='etapas'type = "text" fluid placeholder = 'Etapa' value = {this.state.etapa} onChange = {this.alteraEtapa.bind(this)}/>               
              <datalist id='etapas'>                
                <option value='BRASSAGEM' />
                <option value='FERMENTAÇÃO' />
                <option value='MATURAÇÃO' />
                <option value='FILTRAÇÃO' />
                <option value='ENVASAMENTO'/>
              </datalist>
            </Form.Field>
              <Form.Input width={2} type = "text" fluid label = 'Código'  placeholder = 'Código' value = {this.state.codigo} onChange = {this.alteraCodigo.bind(this)}/>                
              <Form.Input width={5} type = "text" fluid label = 'Insumo'  placeholder = 'Insumo' value = {this.state.insumo} onChange = {this.alteraInsumo.bind(this)}/> 
              <Form.Input width={2} type = "number" fluid label = 'Quantidade'  placeholder = 'Quantidade' value = {this.state.quantidade} onChange = {this.alteraQuantidade.bind(this)}/> 
              <Form.Input width={1} type = "text" fluid label = 'UM'  placeholder = 'UM' value = {this.state.um} onChange = {this.alteraUM.bind(this)}/> 
          < /Form.Group >
          
          <Button loading={this.state.processando} disabled={this.state.processando} onClick = {this.handleClick} primary icon labelPosition = 'left' > < Icon name = 'save' / > Salvar < /Button>           
        < /Form > 
        </Modal.Content> 
    < /Modal >
      );
    }
  }

  export default EditaInsumo;
